<!-- eslint-disable camelcase -->
<template>
  <div>
    <div v-if="notFound">
      <b-card class="text-center">
        <feather-icon
          size="80"
          icon="SearchIcon"
        />
        <div class="error mt-2">
          <h2 class="my-2">
            User not found
          </h2>
          <b-button
            variant="outline-primary"
            @click="() => $router.push({ name: 'users' })"
          >
            Back to users
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <div class="contact-box center-version">
              <div class="d-flex justify-content-between">
                <div

                  class="text-right text-danger pointer font-weight-bold"
                  @click="openRemoveUsrDialoge(viewData.id)"
                >
                  <span title="Remove from campaign">Remove</span>

                </div>
                <div
                  v-if="viewData.status === 'Blocked'"
                  class="text-right text-danger pointer font-weight-bold"
                  @click="blockUser(viewData.id, 0)"
                >
                  Unblock
                </div>
                <div
                  v-else
                  class="text-right text-danger pointer font-weight-bold"
                  @click="blockUser(viewData.id, 1)"
                >
                  Block
                </div>

              </div>

              <div class="top">
                <b-avatar
                  size="100"
                  :src="viewData.avatar_url"
                  :text="avatarText(viewData.name)"
                  class="mb-2"
                />
                <h3 class="mb-2">
                  <strong> {{ viewData.name }} </strong>
                </h3>
              </div>
              <div class="d-flex justify-content-center">
                <b-button
                  variant=""
                  size="sm"
                  class="mx-1"
                  @click="openEditBalanceDialog()"
                >
                  Edit Balance
                </b-button>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="openWalletDialog()"
                >
                  Add Balance
                </b-button>
              </div>
              <div class="user-info">
                <ul>
                  <li>
                    <feather-icon icon="UserIcon" /> Id: {{ viewData.id }}
                  </li>
                  <li>
                    <feather-icon icon="MailIcon" /> Email: {{ viewData.email }}
                  </li>
                  <li>
                    <feather-icon icon="DollarSignIcon" /> Wallet :
                    {{ viewData.current_balance }}
                    <!-- <feather-icon
                      color="#f48a36"
                      icon="EditIcon"
                      class="pointer"
                      size="20"
                      @click="openWalletDialog()"
                    /> -->
                  </li>

                  <li>
                    <feather-icon icon="PhoneIcon" /> Phone:
                    {{ viewData.mobile }}
                  </li>

                  <li>
                    <feather-icon icon="CalendarIcon" /> Date:
                    {{ viewData.created_at | formatDate }}
                  </li>
                  <li>
                    <feather-icon icon="UserCheckIcon" /> verified:
                    <feather-icon
                      v-if="viewData.is_verified == 'Verified'"
                      icon="CheckCircleIcon"
                      color="green"
                      height="20"
                      width="20"
                    />
                    <feather-icon
                      v-else
                      icon="XCircleIcon"
                      color="red"
                      height="20"
                      width="20"
                    />
                  </li>
                  <li>
                    <feather-icon icon="ActivityIcon" /> Status:

                    <b-badge
                      class="change-status"
                      :variant="changeVaraint(viewData.status)"
                    >
                      {{ viewData.status }}
                    </b-badge>
                  </li>
                </ul>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <div>
            <b-card no-body>
              <b-tabs card>
                <b-tab
                  title="Orders"
                  active
                >
                  <div>
                    <div
                      v-if="loaderOrders"
                      class="text-center mt-5"
                    >
                      <b-spinner
                        type="grow"
                        label="Loading..."
                      />
                    </div>
                    <b-table
                      v-if="!loaderOrders"
                      ref="userOrdersTable"
                      :items="userOrders"
                      :fields="columnsOrder"
                      aria-busy="true"
                      class="position-relative"
                      responsive
                      show-empty
                      bordered
                      primary-key="id"
                    >
                      <template #cell(date)="data">
                        <span>{{ data.item.date | formatDate }}</span>
                      </template>
                      <template #cell(actions)="data">
                        <span title="View">
                          <router-link
                            :to="{
                              name: 'show-order',
                              params: { id: data.item.id },
                            }"
                          >
                            <feather-icon
                              icon="EyeIcon"
                              class="cursor-pointer order-view"
                            /></router-link>
                        </span>
                      </template>
                      <template #cell(items_with_price)="data">
                        <ul>
                          <li
                            v-for="(item, index) in data.item.items_with_price"
                            :key="index"
                            class="itemPrice"
                          >
                            <span>product: {{ item.product_name }} ,</span>
                            <span> Qty: {{ item.quantity }} , </span>
                            <span>price: {{ item.price }} </span>
                          </li>
                        </ul>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPageOrders"
                      :total-rows="totalRowsOrders"
                      :per-page="perPage"
                      align="end"
                      first-number
                      last-number
                      :total-visible="7"
                      :length="Math.ceil(totalRowsOrders / 10)"
                      class="mb-0 mt-1"
                      prev-class="prev-item"
                      next-class="next-item"
                      aria-controls="my-table"
                      @click.native="getUserOrders($event)"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="20"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </b-tab>
                <b-tab title="Transactions">
                  <div>
                    <b-table
                      ref="transactionsTable"
                      :items="transactionsData"
                      :fields="columns"
                      aria-busy="true"
                      class="position-relative"
                      responsive
                      show-empty
                      bordered
                      primary-key="id"
                    >
                      <template #cell(amount)="data">
                        <span>{{ removeLastTwoDigits(data.item.amount) }}</span>
                      </template>
                      <template #cell(confirmed)="data">
                        <span>{{ confirmed(data.item.confirmed) }}</span>
                      </template>
                    </b-table>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="end"
                      first-number
                      last-number
                      :total-visible="7"
                      :length="Math.ceil(totalRows / 10)"
                      class="mb-0 mt-1"
                      prev-class="prev-item"
                      next-class="next-item"
                      aria-controls="my-table"
                      @click.native="getdata($event)"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="20"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </b-tab>
                <b-tab title="Blocked Campaigns">
                  <template>
                    <b-row class="justify-content-end mr-1">
                      <b-button
                        v-if="selected.length > 0 && !unblockFrmCampaignloader"
                        class="mb-1"
                        variant="primary"
                        @click="unblockFrmCampaign()"
                      >
                        <span class="text-nowrap"> Unblock from campaign </span>
                      </b-button>
                      <b-button
                        v-if="unblockFrmCampaignloader"
                        variant="primary"
                        disabled
                        class="mr-1 mb-1"
                      >
                        <b-spinner
                          small
                          type="grow"
                        />
                        Loading...
                      </b-button>
                    </b-row>
                  </template>
                  <div>
                    <b-table
                      ref="blockedTable"
                      selectable
                      :items="blockedCampaigns"
                      :fields="blockedColumns"
                      aria-busy="true"
                      class="position-relative"
                      responsive
                      show-empty
                      bordered
                      primary-key="id"
                      @row-selected="onRowSelected"
                    >
                      <template #cell(select)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <span aria-hidden="true">&check;</span>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <b-form-checkbox
                            class="custom-control-primary"
                            disabled
                          />
                        </template>
                      </template>
                    </b-table>

                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-modal
        id="removeUsr"
        ref="removeUsr"
        hide-footer
        centered
        title="Remove User From Campaign"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>

            <b-col md="12">
              <validation-provider
                #default="validationContext"
                name="product_gifts"
              >
                <b-form-group
                  label="Choose Campaign "
                  label-for="product_gifts"
                >
                  <v-select
                    v-model="removeCampaignForm.ids"
                    label="name"
                    multiple
                    :close-on-select="false"
                    :options="product_gifts"
                    :filterable="false"
                    :searchable="false"
                    :reduce="product_gifts => product_gifts.id"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row></validation-observer>

        <b-col
          cols="12"
          md="12"
          class="mt-1"
        >
          <b-button
            v-if="!removeUserFromCampaignloader"
            variant="primary"
            class="mr-1"
            @click.prevent="removeUserFromCampaign"
          >
            Remove
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeRemoveUsrModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="removeUserFromCampaignloader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="addBalance"
        ref="addBalance"
        hide-footer
        centered
        title="Add Balance"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="12">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Amount"
                rules="required"
              >
                <b-form-group
                  label="Amount"
                  label-for="name"
                >
                  <b-form-input
                    id="amount"
                    v-model="addBalanceAmount"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Amount"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <small>Note: The given amount will be added to the available
                balance</small>
            </b-col>
          </b-row></validation-observer>

        <b-col
          cols="12"
          md="12"
          class="mt-1"
        >
          <b-button
            v-if="!loaderStep"
            variant="primary"
            class="mr-1"
            @click.prevent="addBalance"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeAddBalanceModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderStep"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
    <div>
      <b-modal
        id="editWallet"
        ref="editWallet"
        hide-footer
        centered
        title="Edit Wallet"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col md="12">
              <!-- Name -->
              <validation-provider
                #default="validationContext"
                name="Amount"
                rules="required"
              >
                <b-form-group
                  label="Amount"
                  label-for="name"
                >
                  <b-form-input
                    id="amount"
                    v-model="amount"

                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Amount"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col class="mb-2">
              <small>Note: The given amount will replace the current amount</small>
            </b-col>
          </b-row></validation-observer>

        <b-col
          cols="12"
          md="12"
        >
          <b-button
            v-if="!loaderStep"
            variant="primary"
            class="mr-1"
            @click="editWallet"
          >
            Save
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="loaderStep"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'
import user from '../users'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      totalRows: 0,
      loaderStep: false,
      unblockFrmCampaignloader: false,
      removeUserFromCampaignloader: false,
      totalRowsOrders: 0,
      perPage: 10,
      amount: '',
      addBalanceAmount: '',
      product_gifts: [],
      removeCampaignForm: {},
      selected: [],

    }
  },
  setup() {
    const { viewData, userForm } = user()
    const { getValidationState } = formValidation()

    const { formData, setFormData } = handleFormData()
    const columns = [
      { key: 'id', sortable: true },
      { key: 'type', label: 'Type' },
      { key: 'amount', label: 'Amount' },
      { key: 'confirmed', label: 'Confirmed' },
      { key: 'created_at', label: 'Created At' },
    ]
    const blockedColumns = [

      { key: 'select' },
      { key: 'id', label: 'Id' },
      { key: 'name', label: 'Name' },

    ]
    const columnsOrder = [
      { key: 'date', label: 'Date' },
      { key: 'items_with_price', label: 'Products' },
      { key: 'payment method', label: 'Payment Method' },
      { key: 'total', label: 'Total' },
      { key: 'status', label: 'Order Status' },
      { key: 'actions' },
    ]
    const currentPageOrders = ref(1)

    const currentPage = ref(1)
    const userAddresses = ref([])
    const userFiles = ref([])
    const loaderOrders = ref(false)
    const loader = ref(false)
    const notFound = ref(false)
    const userOrders = ref([])
    // eslint-disable-next-line camelcase
    const selected_active_type = ref('')

    const options = [{ value: 'national_id', name: 'National ID' }]
    const transactionsData = ''
    const blockedCampaigns = ''
    const activeType = [
      { text: 'Accepted', value: 'Accepted' },
      { text: 'Rejected', value: 'Rejected' },
      { text: 'Pending', value: 'Pending' },
      { text: 'Hold', value: 'Hold' },
    ]

    return {
      viewData,
      columns,
      currentPage,
      transactionsData,
      blockedCampaigns,
      blockedColumns,
      userAddresses,
      userFiles,
      userOrders,
      loaderOrders,
      options,
      loader,
      columnsOrder,
      currentPageOrders,
      avatarText,
      notFound,
      activeType,
      userForm,
      getValidationState,
      selected_active_type,
      types: {},
      formData,
      setFormData,
    }
  },
  computed: {
    disableUpdateStatusBtn() {
      return (
        this.selected_active_type === 'Rejected'
        && !this.userForm.rejection_reason
      )
    },
  },
  created() {
    this.getdata()
    this.getproduct_gifts()
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },
    unblockFrmCampaign() {
      this.unblockFrmCampaignloader = true
      const formData = new FormData()
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      formData.append('type', 1)

      this.selected.forEach(campaign => {
        formData.append('product_gift_ids[]', campaign)
      })

      axios.post(`admin/block_from_product_gifts/${this.$route.params.id}`, formData).then(res => {
        if (res.status === 200) {
          this.getdata()
          this.$toasted.show('unblocked Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.unblockFrmCampaignloader = false
      })
    },
    removeUserFromCampaign() {
      this.removeUserFromCampaignloader = true
      const formData = new FormData()
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      formData.append('type', 2)

      this.removeCampaignForm.ids.forEach(campaign => {
        formData.append('product_gift_ids[]', campaign)
      })

      axios.post(`admin/block_from_product_gifts/${this.$route.params.id}`, formData).then(res => {
        if (res.status === 200) {
          this.$refs.removeUsr.hide()

          this.getdata()
          this.$toasted.show('Removed Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 422) {
          if (error.response.data.errors !== undefined) {
            this.errorMsg = error.response.data.errors
          } else {
            this.errorMsg = error.response.data.message
          }
        }
      }).finally(() => {
        this.removeUserFromCampaignloader = false
      })
    },
    onRowSelected(items) {
      const rows = []
      // eslint-disable-next-line array-callback-return
      items.map(item => {
        rows.push(item.id)
      })
      this.selected = rows
    },
    blockUser(id, blockValue) {
      const blockParams = {
        is_blocked: blockValue,
      }
      if (blockValue === 1) {
        this.$swal({
          title: 'Block',
          text: 'Are you sure you want to block this user?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Block!',
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .post(`admin/change_blocked_status/${id}`, blockParams)
              .then(() => {
                this.$swal('Blocked!', 'Blocked Successfully.', 'success')

                this.getdata()
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
      } else {
        this.$swal({
          title: 'UnBlock',
          text: 'Are you sure you want to unblock this user?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, UnBlock!',
        }).then(result => {
          if (result.isConfirmed) {
            axios
              .post(`admin/change_blocked_status/${id}`, blockParams)
              .then(() => {
                this.$swal('UnBlocked!', 'UnBlocked Successfully.', 'success')

                this.getdata()
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
      }
    },
    addBalance() {
      this.loaderStep = true

      axios
        .post(`admin/change_wallet_amount/${this.$route.params.id}`, {
          amount: this.addBalanceAmount,
        })
        .then(res => {
          if (res.status === 200) {
            this.$refs.addBalance.hide()
            this.getdata()

            this.$toasted.show('Added Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        })
        .finally(() => {
          this.loaderStep = false
        })
    },
    editWallet() {
      this.loaderStep = true

      axios
        .post(`admin/edit_wallet_amount/${this.$route.params.id}`, {
          amount: this.amount,
        })
        .then(res => {
          if (res.status === 200) {
            this.$refs.editWallet.hide()
            this.getdata()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        })
        .finally(() => {
          this.loaderStep = false
        })
    },
    changeActiveType(data) {
      this.userForm = data
      // this.filterTypes(data.active_type)
      this.selected_active_type = this.viewData.active_type
      this.$bvModal.show('userACtiveType')
    },
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'
      return 'danger'
    },
    openWalletDialog() {
      this.addBalanceAmount = 0
      this.$refs.addBalance.show()
    },
    openRemoveUsrDialoge() {
      this.$refs.removeUsr.show()
    },
    openEditBalanceDialog() {
      this.amount = this.viewData.current_balance
      this.$refs.editWallet.show()
    },
    closeRemoveUsrModal() {
      this.$refs.removeUsr.hide()
    },
    closeModal() {
      this.$refs.editWallet.hide()
    },
    closeAddBalanceModal() {
      this.$refs.addBalance.hide()
    },
    removeLastTwoDigits(str) {
      return str.substring(0, str.length - 2)
    },
    confirmed(type) {
      if (type === 1) return 'Yes'
      return 'No'
    },
    getdata() {
      let skip
      if (this.currentPage !== 1) {
        skip = this.currentPage * 10 - 10
      } else {
        skip = 0
      }
      this.loader = true
      axios
        .get(`admin/user/${this.$route.params.id}?skip=${skip}`)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.viewData = res.data?.data.user
            this.blockedCampaigns = res.data?.data.blocked_product_gifts
            this.transactionsData = res.data?.data.transactions
            this.totalRows = res.data.data.count
            this.getUserOrders()
          }
        })
        .catch(error => {
          if (error.response.status === 400 || error.response.status === 404) {
            this.notFound = true
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
    async searchproduct_gifts(searchQuery) {
      await axios.get(`admin/product_gift_has_order?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.product_gifts
        }
      })
    },
    async getproduct_gifts() {
      await axios.get(`admin/product_gift_has_order/${this.$route.params.id}`).then(res => {
        if (res.status === 200) {
          this.product_gifts = res.data?.data.product_gifts
        }
      })
    },
    getUserOrders() {
      let skip
      if (this.currentPageOrders !== 1) {
        skip = this.currentPageOrders * 10 - 10
      } else {
        skip = 0
      }
      this.loaderOrders = true
      axios
        .get(`admin/orders?user_id=${this.viewData.id}&skip=${skip}`)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.userOrders = res.data?.data.orders
            this.totalRowsOrders = res.data.data.count
          }
        })
        .catch(error => {
          if (error.response.status === 400 || error.response.status === 404) {
            this.notFound = true
          }
        })
        .finally(() => {
          this.loaderOrders = false
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.order-view {
  color: #6e6b7b !important;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a {
  text-decoration: none !important;
}

.user-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
</style>
