import { ref } from '@vue/composition-api'
import axios from 'axios'
import store from '@/store'

export default function user() {
  const userForm = ref({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    username: '',
    gender: '',
    password: '',
    pin: '',
    device_id: '',
    app: '',
    role: '2',
    roles: [],
    profile_picture: '',
    activation_at: '',
  })

  const viewData = ref({})

  const genderOptions = [
    { name: 'Male', value: 'male' },
    { name: 'Female', value: 'female' },
  ]

  const rolesOptions = [
    { name: 'Admin', value: '0' },
    { name: 'Merchant', value: '1' },
    { name: 'Customer', value: '2' },
  ]

  const roles = ref([])
  const { id } = store.state.generalIds
  const showUser = () => {
    if (id) {
      axios.get(`users/${id}`).then(res => {
        viewData.value = res.data.data
      })
    }
  }
  showUser()

  const resolveRoleName = role => {
    if (role === 0) return 'Admin'
    if (role === 1) return 'Merchant'
    if (role === 2) return 'Customer'

    return 'not'
  }
  const resolveActiveUser = role => {
    if (role === true) return 'Active'

    return 'Not Active'
  }

  return {
    userForm,
    genderOptions,
    rolesOptions,
    showUser,
    viewData,
    resolveRoleName,
    resolveActiveUser,
    roles,
  }
}
